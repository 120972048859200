import { tns } from "tiny-slider/src/tiny-slider"

/*=====================================
=            Slideshow                 =
=======================================*/
if($('.search-form-slider').length) {
    var $slideshowHome = tns({
        container: '.search-form-slider',
        autoplayButtonOutput: false,
        autoplay: true,
        nav: false,
        controlsText: ['<div class="owl-prev"><i class="fa fa-angle-left ico-arrow-left-slider"></i></div>', '<div class="owl-next"><i class="fa fa-angle-right ico-arrow-right-slider"></i></div>'],
    });
}

